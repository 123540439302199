import React from 'react';
import { Pagination } from 'react-admin';
import appStyles from '../styles';

export const ResourcePagination = props => {
    return (
        <div style={appStyles.dark.layout.list.pagination}>
            <Pagination {...props} />
        </div>
    )
}
