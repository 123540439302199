// import jsonServerProvider from 'ra-data-json-server';
import JsonServerProvider from './JsonServerProvider';
import { fetchUtils } from 'ra-core';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

    if(localStorage && localStorage.getItem('token')) {
        options.headers.set('x-access-token', localStorage.getItem('token'));
    }

    return fetchUtils.fetchJson(url, options);  
}
const RestClient = JsonServerProvider(`${process.env.REACT_APP_BASE_API_ENDPOINT}/admin`, httpClient);

export default RestClient