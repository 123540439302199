import React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import appStyles from '../../Theme/styles';
import { ResourcePagination } from '../../Theme/components/ResourcePagination';

const listStyles = makeStyles({
    root:appStyles.dark.layout.list.root
});
const gridStyles = makeStyles(appStyles.dark.layout.list.datagrid);

export const PlatformList = (props) => { 
    const listClass = listStyles();
    const gridClass = gridStyles();
    return (
        <List {...props} className={listClass.root} pagination={<ResourcePagination/>}>
            <Datagrid classes={gridClass}>
                <TextField source="code" />
                <TextField source="name" />
                <TextField source="companyName" />
                <EditButton />
                <DeleteButton />
            </Datagrid>
        </List>
    );
}