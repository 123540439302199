import { Layout, AppBar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import appStyles from './styles';
import Notification from './components/Notification';

const appLayoutStyles = makeStyles({
    root:appStyles.dark.layout.root,
});

const appBarStyles = makeStyles({
    root: appStyles.dark.layout.appBar,
});

const dFlowAppBar = props => {
    const classes = appBarStyles();
    return (
        <AppBar {...props} className={classes.root}></AppBar>
    );
};

const dflowLayout = (props) => {
    const classes = appLayoutStyles();
    return (
    <Layout
        {...props}
        className={classes.root}
        appBar={dFlowAppBar}
        notification={Notification}
    />
    );
}

export default dflowLayout;