import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import appStyles from '../../Theme/styles';
import { ResourcePagination } from '../../Theme/components/ResourcePagination';

const listStyles = makeStyles({
    root:appStyles.dark.layout.list.root
});
const gridStyles = makeStyles(appStyles.dark.layout.list.datagrid);

export const RoleList = (props) => {
    const listClass = listStyles();
    const gridClass = gridStyles();

    return (
        <List {...props} className={listClass.root} pagination={<ResourcePagination/>} bulkActionButtons={false}>
            <Datagrid classes={gridClass}>
                <TextField source="id" />
                <TextField source="label" />
            </Datagrid>
        </List>
    );
}