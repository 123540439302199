const authProvider = {
    login: ({ username, password }) =>  {
        const request = new Request(`${process.env.REACT_APP_BASE_API_ENDPOINT}/admin/auth/login`, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(authResponse => {
                if(authResponse.data) {
                    localStorage.setItem('token', authResponse.data);
                } else {
                    throw new Error("Something went wrong");
                }                
            })
            .catch(() => {
                throw new Error('Network error')
            });
    },
    checkError: error => Promise.resolve(),
    checkAuth: () => {
        let isAuth = localStorage && localStorage.getItem("token") ? localStorage.getItem("token") : null;
        if(isAuth) {
            return Promise.resolve()
        } else {
            return Promise.reject()
        }
    },
    logout: () => {
        localStorage.removeItem('token')
        return Promise.resolve()
    },
    getIdentity: () => Promise.resolve(),
    // authorization
    getPermissions: params => Promise.resolve(),
};

export default authProvider;