import * as React from "react";
import { Create, Edit, SimpleForm, TextInput, required } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

export const PlatformCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="code" label="Code" validate={required()} fullWidth={true} />
            <TextInput source="name" label="Name" validate={required()} fullWidth={true} />
            <TextInput source="base_api_endpoint" label="Base API Endpoint" validate={required()} fullWidth={true} />
            <TextInput source="base_uploader_endpoint" label="Base Uploader Endpoint" validate={required()} fullWidth={true} />
            <TextInput source="socket_url" label="Socket Url" validate={required()} fullWidth={true} />
            <TextInput source="url" label="Landing Page URL" fullWidth={true} />
            <TextInput source="companyName" label="Company Name" fullWidth={true} />
            <RichTextInput source="description" label="Description" fullWidth={true} />
        </SimpleForm>
    </Create>
);

export const PlatformEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id" fullWidth={true} />
            <TextInput label="Code" source="code" validate={required()} fullWidth={true} />
            <TextInput source="name" label="Name" validate={required()} fullWidth={true} />
            <TextInput source="base_api_endpoint" label="Base API Endpoint" validate={required()} fullWidth={true} />
            <TextInput source="base_uploader_endpoint" label="Base Uploader Endpoint" validate={required()} fullWidth={true} />
            <TextInput source="socket_url" label="Socket Url" validate={required()} fullWidth={true} />
            <TextInput source="url" label="Landing Page URL" fullWidth={true} />
            <TextInput source="companyName" label="Company Name" fullWidth={true} />
            <RichTextInput source="description" label="Description" fullWidth={true} />
        </SimpleForm>
    </Edit>
);