const appStyles = {
    dark: {
        layout: {
            root: {
                backgroundColor: '#001E3C'
            },
            appBar: {
                backgroundColor: '#212121'
            },
            list: {
                root: {
                    background: 'none',
                },
                datagrid: {
                    table: {
                        backgroundColor: '#001E3C',
                    },
                    headerCell: {
                        backgroundColor: '#212121',
                    },
                },
                pagination: {
                    backgroundColor: '#212121'
                }
            }
        }      
    },
};

export default appStyles;