// import logo from './logo.svg';
import './App.css';

import * as React from "react";
import { Admin, Resource, defaultTheme } from 'react-admin';
import RestClient from "./Utils/RestClient";
import authProvider from './Auth/authProvider';
import { Helmet } from 'react-helmet';
import { createTheme } from '@material-ui/core/styles';

import dflowLayout from './Theme/dflowLayout';
import UserList from './Resources/Users/List';
import { UserCreate, UserEdit} from './Resources/Users/CreateUpdate';
import { PlatformList } from './Resources/Platforms/List';
import { PlatformCreate, PlatformEdit} from './Resources/Platforms/CreateUpdate';
import { RoleList } from './Resources/Roles/List';

const theme = createTheme({
  ...defaultTheme,
  palette: {
    type: 'dark',
  },
});

function App() {
  return (
    <div className={`dflow-central-app`}>
      <Helmet>
        <title>Dflow app</title>
      </Helmet>
      <Admin layout={dflowLayout} authProvider={authProvider} dataProvider={RestClient} theme={theme}>
          <Resource name="roles" list={RoleList} />
          <Resource name="users" list={UserList} create={UserCreate} edit={UserEdit} />
          <Resource name="platforms" list={PlatformList} create={PlatformCreate} edit={PlatformEdit}  />
      </Admin>
    </div>
  );
}

export default App;
