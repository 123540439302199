import React, { Fragment } from 'react';
import { List, Datagrid, TextField, EditButton, BooleanField, DeleteButton, CreateButton,  ExportButton, TopToolbar, BulkDeleteWithConfirmButton, TextInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import appStyles from '../../Theme/styles';
import { ResourcePagination } from '../../Theme/components/ResourcePagination';
import PublishIcon from '@material-ui/icons/Publish';

const listStyles = makeStyles({
    root:appStyles.dark.layout.list.root
});
const gridStyles = makeStyles(appStyles.dark.layout.list.datagrid);

const ListActions = (props) => {
    return (
        <TopToolbar>
            <CreateButton />
            <ExportButton icon={<PublishIcon />}/>
        </TopToolbar>
    );
};

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);

const filters = [
    <TextInput label="Search" source="filter" alwaysOn />
];

const UserList = (props) => {
    const listClass = listStyles();
    const gridClass = gridStyles();

    return (
        <List {...props} className={listClass.root} pagination={<ResourcePagination/>} actions={<ListActions />} bulkActionButtons={<PostBulkActionButtons />} filters={filters}>
            <Datagrid classes={gridClass}>
                <TextField source="firstName" />
                <TextField source="lastName" />
                <TextField source="username" />
                <TextField source="email" />
                <TextField source="role" />
                <BooleanField source="active" />
                <EditButton />
                <DeleteButton mutationMode="pessimistic"/>
            </Datagrid>
        </List>
    );
}

export default UserList;