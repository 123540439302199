import React, {useEffect, useState, useCallback} from "react";
import { makeStyles } from '@material-ui/core/styles';
import RestClient from "../../Utils/RestClient";
import { 
    Create, 
    Edit, 
    SimpleForm, 
    TextInput, 
    required, 
    PasswordInput, 
    AutocompleteInput, 
    email, 
    BooleanInput,
    useMutation,
    Toolbar,
    SaveButton,
    DeleteButton
} from 'react-admin';
import Popup from '../../Theme/components/Popup';

const createRepeatPasswordValidation = (value, allValues) => {
    if (!value) {
        return 'Please repeat-password';
    }
    if(allValues.password !== value) {
        return 'Password not same!';
    }
    return undefined;
};
const updateRepeatPasswordValidation = (value, allValues) => {
    if (!value) {
        return 'Please input new password';
    }
    if (!value && allValues.password) {
        return 'Please repeat new password';
    }
    if(value && allValues.password && allValues.password !== value) {
        return 'Password not same!';
    }
    return undefined;
};

const validateEmail = [required(), email()];

export const UserCreate = (props) => {
    const [roleOptions, setRoleOptions] = useState([]);
    const [mutate] = useMutation();
    const [popupData, setPopupData] = useState({
        isOpen: false,
        title: '',
        content: '',
        closeAction: () => {}
    });

    const save = useCallback(
        async (values) => {
            try {
                let response = await mutate({
                    type: 'create',
                    resource: 'users',
                    payload: { data: values },
                }, { returnPromise: true });
                if(response && response.data && response.data.id) {
                    if(response.data.serverData && response.data.serverData.popupMassage) {
                        setPopupData({
                            isOpen: true,
                            title: 'User created successfully',
                            content: response.data.serverData.popupMassage,
                            closeAction: () => {
                                setPopupData({
                                    isOpen: false,
                                    title: '',
                                    content: '',
                                    closeAction: () => {}
                                });
                                props.history.push(`/users/${response.data.id}`)
                            }
                        })
                    } else {
                        props.history.push(`/users/${response.data.id}`)
                    }
                }
            } catch (error) {
                if (error.body.errors) {
                    return error.body.errors;
                } else {
                    return error;
                }
            }
        },
        [mutate, props],
    );

    useEffect(() => {
        RestClient.getList('roles', {
            pagination: { page: 1, perPage: 5 },
            sort: { field: 'label', order: 'ASC' }
        }).then(result=> {
            if(result.data) {
                setRoleOptions(result.data)
            } else {
                setRoleOptions([])
            }
        }).catch(err => {
            setRoleOptions([])
        })
    }, [])

    return (
        <>
            <Create {...props}>
                <SimpleForm save={save}>
                    <TextInput source="firstName" label="First Name" validate={required()} fullWidth={true} />
                    <TextInput source="lastName" label="Last Name" validate={required()} fullWidth={true} />
                    <TextInput source="username" label="Username" validate={required()} fullWidth={true} />
                    <TextInput source="email" label="Email" validate={validateEmail} fullWidth={true} />
                    <TextInput source="jobTitle" label="Job Title" fullWidth={true} />
                    <BooleanInput source="active" label="Is Active?" fullWidth={true} defaultValue={false} />
                    <PasswordInput source="password" label="Password" validate={required()} fullWidth={true} />
                    <PasswordInput source="repeat_password" label="Re-type Password" validate={[required(), createRepeatPasswordValidation]} fullWidth={true} />
                    <AutocompleteInput source="role" choices={roleOptions} optionText="label" fullWidth={true} validate={required()} />
                </SimpleForm>            
            </Create>
            <Popup isOpen={popupData.isOpen} title={popupData.title} content={popupData.content} closeAction={() => popupData.closeAction()} />
        </>
    )
};

export const UserEdit = (props) => {
    const [roleOptions, setRoleOptions] = useState([]);
    const [changePassword, setChangePassword] = useState(false);
    const [mutate] = useMutation();

    const useStyles = makeStyles({
        toolbar: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    });

    const EditToolbar = props => (
        <Toolbar {...props} classes={useStyles()}>
            <SaveButton />
            <DeleteButton mutationMode="pessimistic" />
        </Toolbar>
    )

    const save = useCallback(
        async (values) => {
            try {
                let response = await mutate({
                    type: 'update',
                    resource: 'users',
                    payload: { id: values.id, data: values },
                }, { returnPromise: true });
                if(response && response.data && response.data.id) {
                    props.history.push(`/users`);
                }
            } catch (error) {
                if (error && error.body && error.body.errors) {
                    return error.body.errors;
                } else {
                    return error;
                }
            }
        },
        [mutate, props],
    );

    useEffect(() => {
        RestClient.getList('roles', {
            pagination: { page: 1, perPage: 5 },
            sort: { field: 'label', order: 'ASC' }
        }).then(result=> {
            if(result.data) {
                setRoleOptions(result.data)
            } else {
                setRoleOptions([])
            }
        }).catch(err => {
            setRoleOptions([])
        })
    }, [])

    return (
        <Edit {...props}>
            <SimpleForm save={save} toolbar={<EditToolbar />}>
                <TextInput disabled label="Id" source="id" fullWidth={true} />
                <TextInput source="firstName" validate={required()} fullWidth={true} />
                <TextInput source="lastName" validate={required()} fullWidth={true} />
                <TextInput source="username" validate={required()} fullWidth={true} />
                <TextInput source="email" validate={validateEmail} fullWidth={true} />
                <TextInput source="jobTitle" label="Job Title" fullWidth={true} />               
                <AutocompleteInput source="role" choices={roleOptions} optionText="label" fullWidth={true} />
                <BooleanInput source="active" label="Is Active?" fullWidth={true} />
                <BooleanInput source="change_password" label="Change password?" fullWidth={true} onChange={(value) => {
                    setChangePassword(value)
                }} />
                {changePassword && (<PasswordInput source="password" label="New Password" validate={required()} fullWidth={true} />)}
                {changePassword && (<PasswordInput source="repeat_password" label="Re-type New Password" validate={[required(), updateRepeatPasswordValidation]} fullWidth={true} />)}                
            </SimpleForm>
        </Edit>
    )
};